import React from "react";
import { Link } from "react-router-dom";

interface Props {
    href?: string;
}

const BackBtn: React.FC<Props> = ({ href }) => {
    return (
        <div className="back">
            {href ? (
                <Link to={href} className="back__back">
                    <svg
                        width="17"
                        height="12"
                        viewBox="0 0 17 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0.969669 5.46967C0.676776 5.76256 0.676776 6.23743 0.969669 6.53033L5.74264 11.3033C6.03553 11.5962 6.51041 11.5962 6.8033 11.3033C7.09619 11.0104 7.09619 10.5355 6.8033 10.2426L2.56066 6L6.8033 1.75736C7.09619 1.46446 7.09619 0.989591 6.8033 0.696698C6.51041 0.403805 6.03553 0.403805 5.74264 0.696698L0.969669 5.46967ZM16.5 5.25L1.5 5.25L1.5 6.75L16.5 6.75L16.5 5.25Z"
                            fill="black"
                        />
                    </svg>
                    Назад
                </Link>
            ) : (
                <span
                    onClick={() => window.history.back()}
                    className="back__back"
                >
                    <svg
                        width="17"
                        height="12"
                        viewBox="0 0 17 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0.969669 5.46967C0.676776 5.76256 0.676776 6.23743 0.969669 6.53033L5.74264 11.3033C6.03553 11.5962 6.51041 11.5962 6.8033 11.3033C7.09619 11.0104 7.09619 10.5355 6.8033 10.2426L2.56066 6L6.8033 1.75736C7.09619 1.46446 7.09619 0.989591 6.8033 0.696698C6.51041 0.403805 6.03553 0.403805 5.74264 0.696698L0.969669 5.46967ZM16.5 5.25L1.5 5.25L1.5 6.75L16.5 6.75L16.5 5.25Z"
                            fill="black"
                        />
                    </svg>
                    Назад
                </span>
            )}
        </div>
    );
};

export default BackBtn;
