import React from "react";
import moment from "moment";
import YouTube from "react-youtube";

import { Timetable } from "../../models/ITimetable";

interface TimetablePageCoverProps extends Timetable {}

const TimetablePageCover: React.FC<TimetablePageCoverProps> = ({
    image,
    videoUrl,
    category,
    title,
    description,
    isRange,
    startDate,
    endDate,
    date,
}) => {
    const getVideoUrl = (url: string): string => {
        const splitUrl = url.split("/");
        const videoId = splitUrl[splitUrl.length - 1];

        return `https://rutube.ru/play/embed/${videoId}`;
    };

    return (
        <div className="timetable-page-cover">
            {videoUrl ? (
                <div className="timetable-page-cover-video">
                    <iframe
                        width="100%"
                        height="405"
                        src={getVideoUrl(videoUrl)}
                        frameBorder="0"
                        allow="clipboard-write; autoplay"
                        allowFullScreen
                    />
                    {/* <YouTube videoId={videoId} /> */}
                </div>
            ) : (
                <div
                    className="timetable-page-cover-image"
                    style={{
                        backgroundImage: `url('${image}')`,
                    }}
                ></div>
            )}

            <div className="timetable-page-cover-text">
                <p className="timetable-page-cover-text__subtitle">
                    {category}
                </p>

                <h2 className="timetable-page-cover-text__title">{title}</h2>

                <p
                    className="timetable-page-cover-text__description"
                    dangerouslySetInnerHTML={{ __html: description }}
                ></p>

                {isRange ? (
                    <h3 className="timetable-block-text__date">
                        {moment(startDate).format("DD")}-
                        {moment(endDate).format("DD MMMM")} в{" "}
                        {moment(startDate).format("HH:mm")}
                    </h3>
                ) : (
                    <h3 className="timetable-block-text__date">
                        {moment(date).format("DD MMMM, HH:mm")}
                    </h3>
                )}
            </div>
        </div>
    );
};

export default TimetablePageCover;
